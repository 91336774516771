
  import Vue from "vue";
  import {MODAL_IDS, MODALS_LOADED} from "@/utils/modal";
  import {SocialLoginType} from "@/utils/social-login";
  import {AppActionTypes} from "@/store/modules/app/actions";

  export default Vue.extend({
    name: "pwa-sign-up-modal",
    components: {
      PwaSignUp: () => import("@/components/PwaSignUp.vue"),
      PwaGoogleLoginButton: () => import('@/components/social-login/PwaGoogleLoginButton.vue'),
    },
    data() {
      return {
        loadContent: false,
        shownFieldsFilled: false,
      };
    },
    mounted(): void {
      MODALS_LOADED[this.modalId] = true;
    },
    computed: {
      modalId(): string {
        return MODAL_IDS.SIGN_UP;
      },
      referUsername(): string {
        return this.$store.getters.modalProps.referUsername || null;
      },
      socialLoginData(): SocialLoginType & {user: Record<string, string>} {
        return this.$store.getters.modalProps.socialLoginData || null;
      },
      hasGoogleClientId(): boolean {
        return Boolean(process.env.VUE_APP_GOOGLE_CLIENT_ID);
      },
    },
    methods: {
      onSignInClick() {
        this.showModalById(MODAL_IDS.LOGIN);
        this.hideModalById(MODAL_IDS.SIGN_UP);
      },
      onShow() {
        this.shownFieldsFilled = false;
        if (!this.loadContent) {
          this.loadContent = true;
        }
      },
      async login(socialLogin: SocialLoginType) {
        try {
          await this.showLoader(true);
          const referer = this.$route.params.lang ? this.$route.path.replace(`/${this.$route.params.lang}/`, '/') : this.$route.path;
          const {data} = await this.$http.auth.postLogin({ social: socialLogin, referer });
          const {token, user} = data.data;
          if (token) {
            await this.onLoginSuccess(token);
            this.hideModalById(this.modalId)
          } else if (user && socialLogin) {
            const socialLoginData = { ...socialLogin, user };
            await this.$store.dispatch(AppActionTypes.SET_MODAL_PROPS, { socialLoginData });
          } else {
            this.showToastError(this.$t('139'), this.$t('362'));
          }
        } catch (error) {
          const response = error?.response || {};
          const title = this.$t('139');
          let message = this.$t('362');
          if (response.status  === 401 && response?.data?.message) {
            message = response.data.message;
          }
          this.showToastError(title, message);
        } finally {
          await this.showLoader(false);
        }
      },
      onShownFieldsFilled() {
        this.shownFieldsFilled = true;
      },
    }
  });
